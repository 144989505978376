* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.notBG {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-x: hidden;
  overflow-y: hidden;
}
